<template>
  <div class="page">
    <Navbar title="证书编辑" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="cert.name"
          label="证书名称"
          placeholder="请输入证书名称"
          show-word-limit
          :rules="[{ required: true, message: '请输入证书名称' }]"
        />
        <van-field
          v-model="cert.number"
          label="证书编号"
          placeholder="请输入证书编号"
          show-word-limit
          :rules="[{ required: true, message: '请输入证书编号' }]"
        />
        <van-field
          v-model="cert.issueDate"
          name="签发日期"
          label="签发日期"
          @click="timeShow = true"
          placeholder="请选择签发日期"
          :rules="[{ required: true, message: '请选择签发日期' }]"
        />
        <van-popup :show="timeShow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            title="选择年月"
            @confirm="timeConfirm"
            @cancel="timeShow = false"
        /></van-popup>
        <van-field name="uploader" label="证书图片">
          <template #input>
            <van-uploader
              v-model="certImages"
              :after-read="certRead"
              :before-delete="imageDelete"
              :max-count="1"
            />
            <span class="tips fc-orange">请上小于2M的证书图片</span>
          </template>
        </van-field>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Icon, Tag, Form, CellGroup, Field, Popup, DatetimePicker, Uploader } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import { reactive } from 'vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader

  },
  setup () {
    const form = reactive({
      name: '',
      number: '',
      issueDate: ''
    })
    return {
      form
    }
  },
  data () {
    return {
      loadingShow: false,
      timeShow: false,
      personCode: '',
      seq: '',
      certImages: [],
      cert: { name: '', number: '', issueDate: '', imageCode: '' },
      currentDate: new Date()
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveCert()
  },
  methods: {
    async imageDelete (image) {
      var pd = { code: image.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/deleteImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (image.key === 'PEP_PSN_CERT') {
          this.cert.imageCode = ''
        }
        return true
      } else {
        return false
      }
    },
    certRead (file) {
      file.key = 'PEP_PSN_CERT'
      this.uploadImage(file, '证书图片')
    },
    async uploadImage (file, alias) {
      var that = this
      this.loadingShow = true
      var pd = { dictKey: file.key, content: file.content, name: file.file.name, alias: alias, attach: 'cert' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/uploadImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        file.code = res.data.code
        if (file.key === 'PEP_PSN_CERT') {
          that.cert.imageCode = res.data.code
        }
      }
      this.loadingShow = false
    },
    async retrieveImage (code, key) {
      var pd = { code: code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/retrieveImagePath', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (key === 'PEP_PSN_CERT') {
          this.certImages.push({ url: res.data.path, code: res.data.code, key: key })
        }
      }
    },
    timeConfirm (time) {
      var year = time.getFullYear()
      var month = time.getMonth() + 1
      var t = year + '-' + (month < 10 ? '0' + month : month)
      this.cert.issueDate = t
      this.timeShow = false
    },
    async retrieveCert () {
      var pd = { personCode: this.personCode, key: 'PSN_CRT_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.cert.name = content.name
        this.cert.number = content.number
        this.cert.issueDate = content.issueDate
        var imageCode = content.imageCode
        if (imageCode !== undefined && imageCode !== '') {
          this.cert.imageCode = imageCode
          this.retrieveImage(imageCode, 'PEP_PSN_CERT')
        }
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_CRT_ITEM', content: JSON.stringify(this.cert), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
